import React from "react"
import {Link} from "gatsby"
import Img from 'gatsby-image'

const ContentfulRelatedStories = (props) => {
  const stories = props.stories
  return (
    <div className="columns is-centered">
    {stories.map(({node}, index) => (
      <div className="column is-4" key={index}>
        <Link to={`/news/${node.slug}`} style={{
            textDecoration: `none`
        }}>
            <Img
            alt={node.title}
            fluid={node.heroImage.fluid}
            style={{marginBottom: '10px'}}
            />
            <p className="has-text-danger has-text is-uppercase has-text-weight-bold">{node.category}</p>
            <h3 className="has-text-black">{node.title}</h3>
            <div className="has-text-grey-dark is-size-4">
                <p>{node.body.childMarkdownRemark.excerpt}</p>
            </div>
            <p className="has-text-grey-dark">{node.body.childMarkdownRemark.timeToRead} mins read</p>
        </Link>
      </div>

    ))}
    </div>
  )

}

export default ContentfulRelatedStories
