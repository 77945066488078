import React from 'react'
import { graphql } from 'gatsby'
import SEO from "../components/seo"
import get from 'lodash/get'
import Img from 'gatsby-image'
import _shuffle from "lodash.shuffle";
import Layout from '../components/layout'
import ContentfulRelatedStories from "../components/news/contentful_related_stories"
import ProductCell from "../components/product/productCell"


class BlogPostTemplate extends React.Component {
    
  
    render() {
      const stories = _shuffle(this.props.data.allContentfulBlogPost.edges).slice(0,2);
      const products = this.props.data.allWcProducts.edges
      const post = get(this.props, 'data.contentfulBlogPost')
      const helmetMeta = post.keywords != null ? [
        {
          name: `keywords`,
          content: post.keywords.join(),
        }
      ]:
      []
      return (
        <Layout isAlternate={true}>
            <SEO title={post.title} meta={helmetMeta} description={post.description.description} image={"https:"+post.heroImage.file.url}/>
           
          <section className="hero has-yellow-background has-absolute-navbar">
            <div className="hero-body">
  
              <div className="container">
                <div className="columns is-centered is-vcentered">
                  <div className="column is-5">
                    <p className="has-text-danger has-text is-uppercase has-text-weight-bold">{post.category}</p>
                    <br/>
                    <h1 className="has-text-white">
                      {post.title}
                    </h1>
                    <div className="has-text-white is-size-3">
                        <p>{post.body.childMarkdownRemark.excerpt}</p>
                        </div>
                    <br/>
                    <p className="has-text-white">
                       {post.body.childMarkdownRemark.timeToRead} mins read
                    </p>
                  </div>
                  <div className="column is-offset-1">
                  <Img
                    alt={post.title}
                    fluid={post.heroImage.fluid}
                    />
                  </div>
  
                </div>
  
              </div>
            </div>
  
          </section>
          <section className="section is-medium has-absolute-navbar">
  
            <div className="container">
              <div className="columns">
                <div className="column is-6">
                  <div className="article-content"
                    dangerouslySetInnerHTML={{
                        __html: post.body.childMarkdownRemark.html,
                    }}
                    />
                  <hr className="has-black-background"/>
  
                  <article className="media">
                    <figure className="media-left">
                      <p className="image is-64x64">
                        
                        <Img
                            alt={post.author.name}
                            fluid={post.author.image.fluid}
                            />
                      </p>
                    </figure>
                    <div className="media-content is-vertical-center">
                      <div className="content">
                        <p className="has-text-weight-bold">{post.author.name}</p>
                        <div className="article-content"
                        dangerouslySetInnerHTML={{
                            __html: post.author.shortBio.childMarkdownRemark.html,
                        }}
                        />
                      </div>
                    </div>
                  </article>
                </div>
  
              </div>
  
            </div>
  
          </section>
          <section className="section">
            <div className="container">
              <h2 className="has-text-centered">You may also like</h2>
              <br/>
              <ContentfulRelatedStories stories={stories}/>
            </div>
          </section>
          <section className="section">
            <div className="container">
            <h1 className="has-text-centered">Here's your<br/>Reason to Smile</h1>
            <br/>
              <div className="columns is-multiline is-centered is-mobile">
                {products.map(({node}, index) => (
                  <ProductCell product={node} key={index}/>
                ))}
              </div>
            </div>
          </section>
        </Layout>
      )
    }
  }

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      category
      keywords
      description {
        description
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid {
          ...GatsbyContentfulFluid
        }
        file {
          url
        }
      }
      body {
        childMarkdownRemark {
          html
          excerpt(format: PLAIN, truncate: false)
          timeToRead
        }
      }
      author {
        name
        image {
            fluid {
                ...GatsbyContentfulFluid
            }
        }
        shortBio {
            childMarkdownRemark {
              html
            }
        }
      }
    }
    allContentfulBlogPost {
      edges {
        node {
          title
          category
          slug
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(format: PLAIN, truncate: false)
              timeToRead
            }
          }
        }
      }
    }
    allWcProducts(filter: {catalog_visibility: {eq: "visible"}, status: {eq: "publish"}, tags: {elemMatch: {name: {eq: "CBD"}}}}, limit: 4) {
      edges {
        node {
          id
          type
          slug
          name
          price
          categories {
            wordpress_id
            name
          }
          acf {
            flavor
            effect
            product_type
          }
          images {
            id
            alt
            src
          }
          grouped_products_nodes {
            id
            wordpress_id
            type
            price
          }
        }
      }
    }
  }
`
